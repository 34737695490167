<template>
  <div class="col">
    <!--
      - show page content only if user is not adding new content or edit existing lesson
      - this is because tiny editor doesn't work properly with popups so it need to be displayed alone in the view,
      so instead of creating new routes, we used v-if vue directive to control what appears on the view
     -->

    <el-card
      style="width: 100%"
      v-loading="$store.getters['courses/load']"
      v-if="!add_content_dialog && !edit_lesson_dialog"
    >
      <template #header>
        <div class="d-flex justify-content-between">
          <h4 style="text-align: right">
            <i class="el-icon-data-analysis"></i>
            {{ getCourseTitle($store.getters["courses/course"].title) }}
          </h4>
          <div v-if="hasRole('admin')">
            <el-button
              size="small"
              type="primary"
              class="mr-2 ml-2"
              @click="open_add_content_dialog"
            >
              <i class="el-icon-plus"></i>
              {{ $t("add_content") }}
            </el-button>
            <el-button
              size="small"
              type="primary"
              @click="open_edit_content_dialog"
            >
              <i class="el-icon-edit"></i>
              {{ $t("edit_course") }}
            </el-button>
          </div>
        </div>
      </template>
      <!-- <el-collapse style="width: 100%" v-if="examFlag == true" accordion>
        <exam :course_id="courseData.id"/>
      </el-collapse> -->
      <el-collapse style="width: 100%" v-model="activeNames" accordion>
        <el-collapse-item
          v-for="(lesson, i) in courseData.lessons"
          :key="i"
          :title="lesson.title"
          :name="`${i}`"
        >
          <template #title>
            <div
              class="d-flex justify-content-between mt-3"
              style="width: 100%"
            >
              <h5 style="line-height: 1.5">
                <i class="el-icon-data-board mr-2 ml-2"></i>
                {{ getLessonTitle(lesson.title) }}
              </h5>
              <!-- ////  Just For Admin Delete And Edit  -->
              <div style="width: 50%" class="mb-3">
                <el-button
                  v-if="hasRole('admin')"
                  icon="el-icon-edit"
                  class="mr-2 ml-2"
                  type="primary"
                  circle
                  size="mini"
                  @click="open_edit_lesson_dialog(lesson)"
                ></el-button>
                <el-button
                  v-if="hasRole('admin')"
                  icon="el-icon-delete"
                  type="danger"
                  circle
                  size="mini"
                  @click="remove_lesson(lesson.id)"
                ></el-button>
              </div>
              <!-- ////  Just For Admin Delete And Edit  -->
            </div>
          </template>
          <el-card>
            <div
              v-if="lesson"
              class="a-content float-end pl-3 pr-4 mb-4 d-flex justify-content-center flex-column align-items-center"
            >
              <div v-if="lesson.type == 3" class="align-items-center">
                <h4
                  v-if="lesson.content != 'null'"
                  class="d-block mb-2 mt-2 font-weight-bolder"
                  v-html="lesson.content"
                ></h4>
                <!-- show for students only  -->
                <el-button
                  v-if="hasRole('student')"
                  type="primary"
                  class="mx-auto my-5 d-block"
                  @click="open_solution_dialog(lesson.teacher_id, lesson.id)"
                >
                  {{ $t("upload_activity") }}</el-button
                >
              </div>

              <div
                v-if="lesson"
                class="a-content float-end pl-3 pr-4 mb-4 d-flex justify-content-center flex-column align-items-center"
              >
                <div class="text-center mt-7 mb-5" v-if="lesson.type == 7">
                  <label for="childers">اختر الطفل</label>
                  <el-select v-model="child_id" class="mr-2">
                    <el-option
                      v-for="item in students"
                      id="childers"
                      :key="item.name"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <br />
                  <br />
                  <div class="align-self-center ml-1">
                    <el-button
                      type="button"
                      @click="capture"
                      class="btn btn-primary"
                    >
                      <i class="fa fa-camera"></i>
                    </el-button>
                  </div>
                  <b>
                    <br />
                  </b>
                  <div class="mb-5 d-flex justify-content-center">
                    <br />
                    <br />
                    <br />
                    <iframe
                      :src="`${lesson.link}`"
                      frameborder="0"
                      width="900"
                      height="700"
                    ></iframe>
                  </div>

                  <div class="col-md-12 text-center">
                    <!-- <h1>{{lesson}}</h1> -->
                    <button
                      type="button"
                      @click="
                        save_solution2(lesson.teacher_id, lesson.id, child_id)
                      "
                      class="btn btn-primary ml-2"
                    >
                      رفع الصورة
                    </button>
                    <button
                      type="button"
                      @click="clearImg"
                      class="btn btn-warning"
                    >
                      حذف الصورة
                    </button>
                  </div>

                  <div class="card mb-5" style="height: 550px">
                    <canvas id="screenshot"></canvas>
                  </div>
                </div>
              </div>
              <video
                style="height: 400px; width: 100%"
                class="mb-3"
                v-else-if="lesson.type == 1"
                controls
              >
                <source
                  :src="`https://justtolearn.com/api/courses/content/${lesson.id}`"
                  type="video/mp4"
                />
              </video>

              <div v-else-if="lesson.type == 2">
                <img
                  style="height: 370px"
                  :src="`https://justtolearn.com/api/courses/content/${lesson.id}`"
                  class="float-end mb-3"
                />
              </div>
              <!--
 <div v-else-if="lesson.type == 6" class="text-center mt-7 mb-5"> -->

              <!-- show for students only  -->
              <!--
                <a
                  v-if="hasRole('student')"
                  type="primary"
                  style="font-size: 16px; font-weight: bolder"
                  link
                  :href="done(lesson.id)"
                  target="_blank"
                >

                </a> -->
              <!-- </div> -->

              <div v-else-if="lesson.type == 5" class="text-center mt-7 mb-5">
                <!-- show for students only  -->

                <a
                  v-if="hasRole('student')"
                  type="primary"
                  style="font-size: 16px; font-weight: bolder"
                  link
                  :href="`https://justtolearn.com/api/courses/content/${lesson.id}`"
                  target="_blank"
                >
                  <i class="el-icon-download"></i>
                  {{ $t("please_upload_file") }}
                </a>
              </div>

              <h4
                v-if="lesson.content != 'null' && lesson.type != 3"
                class="font-weight-bolder"
                v-html="lesson.content"
              ></h4>

              <div class="submit-activity w-100 d-flex justify-content-center">
                <el-button
                  v-if="lesson.type == 4"
                  type="primary"
                  @click="open_solution_dialog(25, lesson.id)"
                >
                  <i class="el-icon-message"></i>
                  {{ $t("send") }}
                </el-button>
              </div>

              <div class="btns w-100">
                <!-- hide in the first lesson -->
                <el-button
                  v-if="parseInt(activeNames) > 0"
                  type="primary"
                  class="prev float-left my-5 d-block"
                  @click="open_prev_lesson"
                >
                  {{ $t("prev") }}</el-button
                >
                <!-- hide in the last lesson -->
                <el-button
                  v-if="courseData.lessons.length - 1 > parseInt(activeNames)"
                  type="primary"
                  class="next float-right my-5 d-block"
                  @click="open_next_lesson"
                >
                  {{ $t("next") }}</el-button
                >
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <!-- //// Start Edit Content Dialog  -->
    <el-dialog
      :title="$t('course_data')"
      width="70%"
      :visible.sync="edit_content_dialog"
      top="4vh"
    >
      <el-form
        :model="edited_content"
        ref="editContentRef"
        :rules="editContentRules"
        v-loading="$store.getters['courses/load']"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24" class="text-center">
            <el-radio-group v-model="selectedLanguage">
              <el-radio-button label="en">{{
                $t("CoursesGroup.english")
              }}</el-radio-button>
              <el-radio-button label="ar">{{
                $t("CoursesGroup.arabic")
              }}</el-radio-button>
              <el-radio-button label="gr">{{
                $t("CoursesGroup.germany")
              }}</el-radio-button>
            </el-radio-group>
          </el-col>

          <!-- <el-col :span="24" :lg="12">
            <el-form-item :label="$t('title')" prop="title">
              <el-input
                v-model="edited_content.title"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'en'">
            <el-form-item
              :label="
                $t('Global.course_name') +
                ' (' +
                $t('CoursesGroup.english') +
                ')'
              "
              prop="title.en"
            >
              <el-input v-model="edited_content.title.en"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'ar'">
            <el-form-item
              :label="
                $t('Global.course_name') +
                ' (' +
                $t('CoursesGroup.arabic') +
                ')'
              "
              prop="title.ar"
            >
              <el-input v-model="edited_content.title.ar"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="24" v-if="selectedLanguage === 'gr'">
            <el-form-item
              :label="
                $t('Global.course_name') +
                ' (' +
                $t('CoursesGroup.germany') +
                ')'
              "
              prop="title.gr"
            >
              <el-input v-model="edited_content.title.gr"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('file')" prop="file" class="file_input">
              <i class="fa fa-file-image-o fa-2x"></i>
              <input
                type="file"
                @change="upload_edit_content_file"
                ref="edit_content_file"
                accept="image/apng, image/jpeg, image/png, image/webp"
              />
              <div class="el-input__inner input_file_placeholder">
                <!-- this to show file name to user since we hide original input for styling -->
                {{ this.edit_content_file_name }}
              </div>
              <!-- <button @click="submitFile">Upload!</button> -->
              <!-- <el-upload
                :on-preview="handlePreview"
                :action="import_url"
                :file-list="edit_content_fileList"
              >
                <el-button size="small" type="primary"
                  >Click to upload</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  jpg/png files with a size less than 500kb
                </div>
              </el-upload> -->
            </el-form-item>
          </el-col>

          <!-- <el-col :span="24">
            <el-form-item :label="$t('description')" prop="description">
              <el-input
                type="textarea"
                v-model="edited_content.description"
                clear="text_area_course"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="24" v-if="selectedLanguage === 'en'">
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.english') + ')'
              "
              prop="description.en"
            >
              <el-input
                type="textarea"
                class="purple-input our_course_text_area"
                v-model="edited_content.description.en"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'ar'">
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.arabic') + ')'
              "
              prop="description.ar"
            >
              <el-input
                type="textarea"
                class="purple-input our_course_text_area"
                v-model="edited_content.description.ar"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'gr'">
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.germany') + ')'
              "
              prop="description.gr"
            >
              <el-input
                type="textarea"
                class="purple-input our_course_text_area"
                v-model="edited_content.description.gr"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="edit_content_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="save_content_edit"
        >
          {{ $t("save") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- //// End Edit Content Dialog  -->

    <!-- Start Add Content Section -- Editor Here  -->

    <!-- <addCourseContent :add_content_dialog = "add_content_dialog" :courseData = "courseData" :id = "id"/> -->
    <div
      class="mx-auto p-5 border border-gray bg-white font-weight-bolder"
      style="width: 80%; max-width: 80rem"
      v-if="add_content_dialog"
    >
      <p class="h3">{{ $t("add_content") }}</p>
      <el-form
        :model="added_content"
        ref="addContentRef"
        :rules="addContentRules"
        v-loading="loading"
        label-position="top"
      >
        <el-row :gutter="20">
          <!-- Language Toggle Button -->
          <el-col :span="24" class="text-center">
            <el-radio-group v-model="selectedLanguage">
              <el-radio-button label="en">{{
                $t("CoursesGroup.english")
              }}</el-radio-button>
              <el-radio-button label="ar">{{
                $t("CoursesGroup.arabic")
              }}</el-radio-button>
              <el-radio-button label="gr">{{
                $t("CoursesGroup.germany")
              }}</el-radio-button>
            </el-radio-group>
          </el-col>

          <!-- Conditional Inputs Based on Selected Language -->
          <el-col :span="24" v-if="selectedLanguage === 'en'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.english') + ')'"
              prop="title.en"
            >
              <el-input
                v-model="added_content.title.en"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.english') + ')'
              "
              prop="description.en"
            >
              <el-input
                type="textarea"
                v-model="added_content.description.en"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'ar'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.arabic') + ')'"
              prop="title.ar"
            >
              <el-input
                v-model="added_content.title.ar"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.arabic') + ')'
              "
              prop="description.ar"
            >
              <el-input
                type="textarea"
                v-model="added_content.description.ar"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'gr'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.germany') + ')'"
              prop="title.gr"
            >
              <el-input
                v-model="added_content.title.gr"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.germany') + ')'
              "
              prop="description.gr"
            >
              <el-input
                type="textarea"
                v-model="added_content.description.gr"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- Kit Selection -->
          <el-col :span="24">
            <el-form-item :label="$t('Select_kit')" prop="kit">
              <el-checkbox-group v-model="added_content.advanced_lessons">
                <el-checkbox label="0" checked>{{ $t("Kit") }}</el-checkbox>
                <el-checkbox label="1">{{ $t("Board") }} (25)</el-checkbox>
                <el-checkbox label="2">{{ $t("Board") }} (50)</el-checkbox>
                <el-checkbox label="3">{{ $t("Board") }} (100)</el-checkbox>
                <el-checkbox label="4">{{ $t("Board") }} (200)</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>

          <!-- Feedback Inputs -->
          <el-col :span="24">
            <el-form-item :label="$t('Add_Feedback')" prop="feedback">
              <div class="feedback-input">
                <el-input
                  v-model="newGrade[selectedLanguage]"
                  :placeholder="$t('grade')"
                  class="input-inline"
                ></el-input>
                <el-input
                  v-model="newExplanation[selectedLanguage]"
                  :placeholder="$t('explanation')"
                  class="input-inline"
                ></el-input>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="addFeedback"
                  >
                  <!-- {{ $t("Add_Feedback") }} -->
                  </el-button
                >
              </div>
            </el-form-item>
          </el-col>

          <!-- Feedback Tags -->

          <el-col
            :span="24"
            v-if="
              added_content.feedbacks[selectedLanguage] &&
              added_content.feedbacks[selectedLanguage].length > 0
            "
          >
            <el-divider>{{ $t("Feedback") }}</el-divider>
            <div class="feedback-item-row">
              <div
                class="feedback-item"
                v-for="(feedback, index) in added_content.feedbacks[
                  selectedLanguage
                ]"
                :key="index"
              >
                <el-tag
                  closable
                  @close="removeFeedback(index, selectedLanguage)"
                >
                  {{ feedback.grade }}: {{ feedback.explanation }}
                </el-tag>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="add_content_dialog = false"
          >{{ $t("cancel") }}</el-button
        >
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="save_content_add"
          >{{ $t("save_only") }}</el-button
        >
      </span>
    </div>
    <!-- End Add Content Section -- Editor Here  -->

    <!-- edit lesson dialog  -->
    <div
      v-if="edit_lesson_dialog"
      class="mx-auto p-5 border border-gray bg-white font-weight-bolder"
      style="width: 80%; max-width: 80rem"
    >
      <p class="h3">{{ $t("edit_lesson") }}</p>
      <el-form
        :model="edited_lesson"
        ref="editLessonRef"
        :rules="editLessonRules"
        v-loading="$store.getters['courses/load']"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24" class="text-center">
            <el-radio-group v-model="selectedLanguage">
              <el-radio-button label="en">{{
                $t("CoursesGroup.english")
              }}</el-radio-button>
              <el-radio-button label="ar">{{
                $t("CoursesGroup.arabic")
              }}</el-radio-button>
              <el-radio-button label="gr">{{
                $t("CoursesGroup.germany")
              }}</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'en'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.english') + ')'"
              prop="title.en"
            >
              <el-input
                v-model="edited_lesson.title.en"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.english') + ')'
              "
              prop="description.en"
            >
              <el-input
                type="textarea"
                v-model="edited_lesson.description.en"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'ar'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.arabic') + ')'"
              prop="title.ar"
            >
              <el-input
                v-model="edited_lesson.title.ar"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.arabic') + ')'
              "
              prop="description.ar"
            >
              <el-input
                type="textarea"
                v-model="edited_lesson.description.ar"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="selectedLanguage === 'gr'">
            <el-form-item
              :label="$t('title') + ' (' + $t('CoursesGroup.germany') + ')'"
              prop="title.gr"
            >
              <el-input
                v-model="edited_lesson.title.gr"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="
                $t('description') + ' (' + $t('CoursesGroup.germany') + ')'
              "
              prop="description.gr"
            >
              <el-input
                type="textarea"
                v-model="edited_lesson.description.gr"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('Select_kit')" prop="kit">
              <el-checkbox-group v-model="edited_lesson.advanced_lessons">
                <el-checkbox label="0">{{ $t("Kit") }}</el-checkbox>
                <el-checkbox label="1">{{ $t("Board") }} (25)</el-checkbox>
                <el-checkbox label="2">{{ $t("Board") }} (50)</el-checkbox>
                <el-checkbox label="3">{{ $t("Board") }} (100)</el-checkbox>
                <el-checkbox label="4">{{ $t("Board") }} (200)</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Add_Feedback')" prop="feedback">
              <div class="feedback-input mb-3">
                <el-input
                  v-model="newGrade[selectedLanguage]"
                  :placeholder="$t('grade')"
                  class="input-inline"
                ></el-input>
                <el-input
                  v-model="newExplanation[selectedLanguage]"
                  :placeholder="$t('explanation')"
                  class="input-inline"
                ></el-input>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="addFeedback"
                  >
                  <!-- {{ $t("Add") }} -->
                </el-button>
              </div>
              <div
                class="feedback-input mb-3"
                v-for="(feedback, index) in edited_lesson.feedbacks[
                  selectedLanguage
                ]"
                :key="index"
              >
                <el-input
                  v-model="feedback.grade"
                  :placeholder="$t('grade')"
                  class="input-inline"
                ></el-input>
                <el-input
                  v-model="feedback.explanation"
                  :placeholder="$t('explanation')"
                  class="input-inline"
                ></el-input>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="removeEditFeedback(index, selectedLanguage)"
                  >
                  <!-- {{ $t("Remove_Feedback") }} -->
                  </el-button
                >
                <!-- <el-button
                        type="primary"
                        icon="el-icon-plus"
                        @click="updateFeedback(index)"
                    >{{ $t("Add") }}</el-button> -->
              </div>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-if="
              edited_lesson.feedbacks[selectedLanguage] &&
              edited_lesson.feedbacks[selectedLanguage].length > 0
            "
          >
            <el-divider>{{ $t("Feedback") }}</el-divider>
            <div class="feedback-item-row">
              <div
                class="feedback-item"
                v-for="(feedback, index) in edited_lesson.feedbacks[
                  selectedLanguage
                ]"
                :key="index"
              >
                <el-tag
                  closable
                  @close="removeEditFeedback(index, selectedLanguage)"
                >
                  {{ feedback.grade }}: {{ feedback.explanation }}
                </el-tag>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="edit_lesson_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="save_lesson_edit"
          >{{ $t("save_only") }}
        </el-button>
      </span>
    </div>

    <!-- Submit solution dialog -->
    <el-dialog
      :title="$t('upload_activity')"
      width="70%"
      :visible.sync="solution_dialog"
      top="4vh"
    >
      <el-form
        :model="solution"
        ref="solutionRef"
        :rules="solutionRules"
        v-loading="$store.getters['courses/load']"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24" :lg="12">
            <el-form-item :label="$t('file')" prop="file" class="file_input">
              <i class="fa fa-file-image-o fa-2x"></i>
              <input
                type="file"
                @change="upload_submit_solution_file"
                ref="submit_solution_file"
                id="hl"
              />
              <label for="hl" :span="24" class="labls">رفع الصورة</label>

              <div class="el-input__inner input_file_placeholder wdth">
                <!-- this to show file name to user since we hide original input for styling -->
                {{ this.submit_solution_file_name }}
              </div>
              <!-- <button @click="submitFile">Upload!</button> -->
              <!-- <el-upload
                :on-preview="handlePreview"
                :action="import_url"
                :file-list="submit_solution_fileList"
              >
                <el-button size="small" type="primary"
                  >Click to upload</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  jpg/png files with a size less than 500kb
                </div>
              </el-upload> -->
            </el-form-item>
          </el-col>

          <el-col :span="24" :lg="12">
            <el-form-item label="الأطفال" prop="student_id">
              <el-select
                v-model="solution.student_id"
                placeholder="الأطفال"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in $store.getters['courses/teachers']"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('message')" prop="message">
              <el-input type="textarea" v-model="solution.message"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="solution_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="save_solution()"
          >{{ $t("upload_activity") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- <div>
      <a href="https://justtolearn.com/api/public/activity"></a>
    </div> -->
  </div>
</template>

<script>
import page from "@tinymce/tinymce-vue";
import Swal from "sweetalert2";
import exam from "@/views/courses/exam";
import html2canvas from "html2canvas";
import $ from "jquery";
import screenshot from "@/utils/helper.js";
import addCourseContent from "./add-course-content.vue";
import { ref } from "vue";

export default {
  components: { page, exam, addCourseContent },

  data() {
    return {
      child_id: null,
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: [],
      checkList: [],
      activeNames: "0",
      examFlag: true,
      // edit content data
      edit_content_dialog: false,
      edited_content: {
        title: {
          en: "",
          ar: "",
          gr: "",
        },
        description: {
          en: "",
          ar: "",
          gr: "",
        },
      },
      editContentRules: {
        title: [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],

        description: [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
      },
      // this to show file name to user since we hide original input for styling
      edit_content_file_name: "",

      // add content data
      add_content_dialog: false,
      loading: false,
      selectedLanguage: "en",
      added_content: {
        title: { en: "", ar: "", gr: "", fr: "" },
        description: { en: "", ar: "", gr: "", fr: "" },
        advanced_lessons: [],
        feedbacks: {
          en: [],
          ar: [],
          gr: [],
          fr: [],
        },
      },
      newGrade: {
        en: "",
        ar: "",
        gr: "",
        fr: "",
      },
      newExplanation: {
        en: "",
        ar: "",
        gr: "",
        fr: "",
      },
      addContentRules: {
        "title.en": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.en": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
        "title.ar": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.ar": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
        "title.gr": [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
        "description.gr": [
          {
            required: true,
            message: this.$t("description_required"),
            trigger: "blur",
          },
        ],
      },

      // this to show file name to user since we hide original input for styling
      add_content_file_name: "",
      types: [
        { value: 1, label: this.$t("video") },
        { value: 4, label: this.$t("activity") },
        { value: 2, label: this.$t("image") },
        { value: 3, label: this.$t("digital_activity") },
        { value: 5, label: this.$t("file") },
        { value: 6, label: this.$t("blank") },
        { value: 7, label: "storyline" },
      ],

      // this is used for both add content and add lesson forms
      init_en: {
        //       selector: 'textarea',  // change this value according to your HTML
        // images_upload_url: 'postAcceptor.php',
        // automatic_uploads: false,
        images_upload_handler: this.imageUpload,
        height: 260,
        mode: "none",
        menubar: "media",
        language: "en",
        toolbar:
          "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify  | media image link | numlist bullist | forecolor backcolor | ltr rtl",
        plugins: ["table charmap imagetools  textpattern media image link"],
      },

      // edit lesson data
      edit_lesson_dialog: false,
      edited_lesson: {
        id: null,
        title: {
          en: null,
          ar: null,
          gr: null,
          fr: null,
        },
        description: {
          en: null,
          ar: null,
          gr: null,
          fr: null,
        },
        feedbacks: null,
        parent_id: null,
        advanced_lessons: [],
      },
      editLessonRules: {
        title: [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
      },

      // submit solution data
      solution_dialog: false,
      solution: {},
      solutionRules: {
        message: [
          {
            required: true,
            message: this.$t("message_required"),
            trigger: "blur",
          },
        ],

        student_id: [
          {
            required: true,
            message: this.$t("children_required"),
            trigger: "change",
          },
        ],
      },
      // this to show file name to user since we hide original input for styling
      submit_solution_file_name: "",
      isTitleWitWhitespace: false,
      newNote: "",
    };
  },
  computed: {
    courseData() {
      return this.$store.getters["courses/course"];
    },
    students() {
      return this.$store.getters["courses/teachers"];
    },
  },

  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      console.log(this.$route.params.id);
      this.$store
        .dispatch("courses/get", this.$route.params.id)
        .then((res) => {});
      this.$store.dispatch("user/get", this.auth().id);
    },

    async capture() {
      console.log("juuu");
      const a = navigator.mediaDevices.getDisplayMedia;

      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: { mediaSource: "screen" },
        });
        const track = stream.getVideoTracks()[0];
        const image = new ImageCapture(track);
        const bitmap = await image.grabFrame();
        track.stop();
        const canvas = document.getElementById("screenshot");
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;
        const context = canvas.getContext("2d");
        // img , x ,y, width , height

        context.drawImage(bitmap, 200, 0, 900, 500);

        const img = canvas.toDataURL();
        this.imgurl = img;
        const res = await fetch(img);
        this.imgurl = img;
        const buff = await res.arrayBuffer();
        const file = [
          new File([buff], "photo_${new Date()}.jpg", {
            type: "image/jpeg",
          }),
        ];

        return file;
      } catch (err) {
        console.error("Error: " + err);
      }
    },

    // reterive data with multi lang
    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
    screenshot(url) {
      screenshot(url);
    },
    sendImg() {
      console.log(this.imgurl);
    },
    clearImg() {
      let canvas = document.getElementById("screenshot");
      const context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);
    },

    image(image) {
      return `http://justtolearn.com/api/course/cover/${image}`;
    },
    imageUpload(blobInfo, success, failure, progress) {
      var formData;
      formData = new FormData();

      formData.append("file", blobInfo.blob(), blobInfo.filename());
      formData.append("id", this.$route.params.id);

      this.$store.dispatch("courses/contentImg", formData).then((response) => {
        progress(100);
        success(this.image(response.url));
      });
    },

    // open next lesson
    open_next_lesson() {
      this.stop_videos();
      let next = parseInt(this.activeNames) + 1;
      this.activeNames = String(next);
    },

    // open previous lesson
    open_prev_lesson() {
      this.stop_videos();
      let prev = parseInt(this.activeNames) - 1;
      this.activeNames = String(prev);
    },
    // stop all videos when moving from obe lesson to another
    stop_videos() {
      document.querySelectorAll("video").forEach((v) => {
        v.pause();
        v.currentTime = 0;
      });
    },

    // edit content methods
    open_edit_content_dialog() {
      this.edit_content_dialog = true;
      // get course data for editing
      // passing this much data to prevent back end error only but I don't see any use of it

      this.edited_content = {
        id: this.courseData.id,

        title: {
          en: JSON.parse(this.courseData.title)["en"],
          ar: JSON.parse(this.courseData.title)["ar"],
          gr: JSON.parse(this.courseData.title)["gr"],
        },
        description: {
          en: JSON.parse(this.courseData.description)["en"],
          ar: JSON.parse(this.courseData.description)["ar"],
          gr: JSON.parse(this.courseData.description)["gr"],
        },
        file: this.courseData.file,
        parent_id: this.courseData.parent_id,
        // type: this.courseData.type,
        // content: this.courseData.content,
      };
    },

    upload_edit_content_file() {
      // console.log(this.$refs["file"].files[0].name);

      // this to show file name to user since we hide original input for styling
      this.edit_content_file_name =
        this.$refs["edit_content_file"].files[0].name;

      // console.log(formData.getAll("file"));
    },
    save_content_edit() {
      this.$refs["editContentRef"].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (let course in this.edited_content) {
            if (course != "id") {
              formData.append(
                course,
                JSON.stringify(this.edited_content[course])
              );

              // formData.append("file", this.$refs["edit_content_file"].files[0]);
            }
          }

          // add edit content image
          // check if there is a file to upload since user has option of not uploading file depending on content type
          if (this.$refs["edit_content_file"]) {
            // add add content image
            formData.append("file", this.$refs["edit_content_file"].files[0]);
          }

          this.$store
            .dispatch("courses/save", {
              id: `/${(this, this.edited_content.id)}`,
              query: formData,
            })
            .then((_) => {
              this.refresh();
              this.edit_content_dialog = false;
              this.edit_content_file_name = "";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // add content methods
    open_add_content_dialog() {
      // store course data to send to the server
      this.added_content = {
        description: this.courseData.description,
        parent_id: this.courseData.id,
        title: { en: "", ar: "", gr: "", fr: "" },
        description: { en: "", ar: "", gr: "", fr: "" },
        advanced_lessons: [],
        feedbacks: {
          en: [],
          ar: [],
          gr: [],
          fr: [],
        },
      };
      this.add_content_dialog = true;
    },

    upload_add_content_file() {
      // console.log(this.$refs["file"].files[0].name);

      // this to show file name to user since we hide original input for styling
      this.add_content_file_name = this.$refs["add_content_file"].files[0].name;

      // console.log(formData.getAll("file"));
    },

    // Handler For Save Content In Editor
    checkWhitespace() {
      let pattern = /[a-zA-Z]/;
      var string = this.added_content.title;
      let ans = pattern.test(string);
      console.log(ans);

      // if (reWhiteSpace.test(this.added_content.title)) {
      //   console.log("mjhjjjjjjjjjjjjjjjjjjjj");
      // }
    },
    addFeedback() {
      const feedback = {
        grade: this.newGrade[this.selectedLanguage].trim(),
        explanation: this.newExplanation[this.selectedLanguage].trim(),
      };

      if (feedback.grade && feedback.explanation) {
        if (this.edit_lesson_dialog) {
          this.edited_lesson.feedbacks[this.selectedLanguage].push(feedback);
        } else {
          this.added_content.feedbacks[this.selectedLanguage].push(feedback);
        }
        this.newGrade[this.selectedLanguage] = "";
        this.newExplanation[this.selectedLanguage] = "";
      } else {
        this.$message({
          message: this.$t("Both grade and explanation are required"),
          type: "warning",
        });
      }
    },
    removeFeedback(index, language) {
      this.added_content.feedbacks[language].splice(index, 1);
    },
    save_content_add() {
      this.$refs["addContentRef"].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (let course in this.added_content) {
            if (course === "feedbacks") {
              formData.append(
                "feedback",
                JSON.stringify(this.added_content.feedbacks)
              );
            } else if (course === "title") {
              formData.append(
                "title",
                JSON.stringify(this.added_content.title)
              );
            } else if (course === "description") {
              formData.append(
                "description",
                JSON.stringify(this.added_content.description)
              );
            } else if (course === "advanced_lessons") {
              // Ensure 'kit' is sent as an array
              const advancedLessons = this.added_content[course].map(Number);
              advancedLessons.forEach((value, index) => {
                formData.append(`advanced_lessons[${index}]`, value);
              });
            } else {
              formData.append(`${course}`, this.added_content[course]);
            }
          }

          if (this.$refs["add_content_file"]) {
            formData.append("file", this.$refs["add_content_file"].files[0]);
          }
          this.$store
            .dispatch("courses/save", {
              id: this.id ? `/${this.id}` : "",
              query: formData,
            })
            .then((_) => {
              this.refresh();
              this.add_content_dialog = false;
              this.add_content_file_name = "";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // edit lesson methods
    open_edit_lesson_dialog(lesson) {
      this.edit_lesson_dialog = true;
      // get course data for editing
      // passing this much data to prevent back end error only but I don't see any use of it
      const cleanAdvancedLessons = lesson.advanced_lessons
        .filter((item) => typeof item === "number" || typeof item === "string")
        .map(String);

      console.log("Cleaned advanced_lessons:", cleanAdvancedLessons);

      const aggregatedFeedbacks = {
        en: [],
        ar: [],
        gr: [],
      };
      // Iterate over all feedback items
      lesson.feedbacks.forEach((feedbackItem) => {
        const feedbacks = JSON.parse(feedbackItem.body);

        // Aggregate feedbacks by language
        Object.keys(aggregatedFeedbacks).forEach((language) => {
          if (feedbacks[language]) {
            aggregatedFeedbacks[language] = [
              ...aggregatedFeedbacks[language],
              ...feedbacks[language],
            ];
          }
        });
      });
      this.edited_lesson = {
        id: lesson.id,
        title: {
          en: JSON.parse(lesson.title)["en"],
          ar: JSON.parse(lesson.title)["ar"],
          gr: JSON.parse(lesson.title)["gr"],
          fr: JSON.parse(lesson.title)["fr"],
        },
        description: {
          en: JSON.parse(lesson.description)["en"],
          ar: JSON.parse(lesson.description)["ar"],
          gr: JSON.parse(lesson.description)["gr"],
          fr: JSON.parse(lesson.description)["fr"],
        },
        feedbacks: aggregatedFeedbacks,
        parent_id: lesson.parent_id,
        advanced_lessons: cleanAdvancedLessons,
      };
      console.log(
        "Processed advanced_lessons:",
        this.edited_lesson.advanced_lessons
      ); // Debugging step
    },
    editFeedback(index, language) {
      const feedback = this.added_content.feedbacks[language][index];
      this.newGrade[language] = feedback.grade;
      this.newExplanation[language] = feedback.explanation;
      this.editingIndex = index; // Track which feedback is being edited
      this.editingLanguage = language; // Track the language of the feedback being edited
    },
    removeEditFeedback(index, language) {
      if (index >= 0 && index < this.edited_lesson.feedbacks[language].length) {
        this.edited_lesson.feedbacks[language].splice(index, 1);
      } else {
        this.$message({
          message: this.$t("Invalid feedback index"),
          type: "warning",
        });
      }
    },
    updateFeedback(index) {
      const updatedFeedback =
        this.edited_lesson.feedbacks[this.selectedLanguage][index];
      console.log("updatedFeedback", index);

      if (updatedFeedback.grade.trim() && updatedFeedback.explanation.trim()) {
        this.$set(
          this.edited_lesson.feedbacks[this.selectedLanguage],
          index,
          updatedFeedback
        );

        this.$message({
          message: this.$t("Feedback updated successfully"),
          type: "success",
        });
      } else {
        this.$message({
          message: this.$t("Both grade and explanation are required"),
          type: "warning",
        });
      }
    },

    save_lesson_edit() {
      // right now, we don't need form data since no file upload but it may be added in her future so I added it to help later
      this.$refs["editLessonRef"].validate((valid) => {
        if (valid) {
          let formData = new FormData();

          for (let course in this.edited_lesson) {
            // if (course != "id") {
            //   formData.append(`${course}`, this.edited_lesson[course]);
            // }
            if (course === "feedbacks") {
              formData.append(
                "feedback",
                JSON.stringify(this.edited_lesson.feedbacks)
              );
            } else if (course === "title") {
              formData.append(
                "title",
                JSON.stringify(this.edited_lesson.title)
              );
            } else if (course === "description") {
              formData.append(
                "description",
                JSON.stringify(this.edited_lesson.description)
              );
            } else if (course === "advanced_lessons") {
              // Ensure 'kit' is sent as an array
              const advancedLessons = this.edited_lesson[course].map(Number);
              advancedLessons.forEach((value, index) => {
                formData.append(`advanced_lessons[${index}]`, value);
              });
            } else {
              formData.append(`${course}`, this.edited_lesson[course]);
            }
          }
          this.$store
            .dispatch("courses/save", {
              id: `/${this.edited_lesson.id}`,
              query: formData,
            })
            .then((_) => {
              this.refresh();
              this.edit_lesson_dialog = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    remove_lesson(id) {
      Swal.fire({
        title: this.$t("confirm_lesson_delete"),
        text: this.$t("ask_lesson_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("courses/delete", id).then((_) => {
            this.refresh();
            Swal.fire({
              title: this.$t("deleted"),
              text: this.$t("lesson_deleted"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    // submit solution methods
    open_solution_dialog(teacher_id, course_id) {
      this.solution_dialog = true;
      this.solution = {
        group_id: this.$route.params.group_id,
        teacher_id,
        course_id,
        selected: 0,
      };
      console.log(this.solution);
    },

    upload_submit_solution_file() {
      // console.log(this.$refs["file"].files[0].name);

      // this to show file name to user since we hide original input for styling
      this.submit_solution_file_name =
        this.$refs["submit_solution_file"].files[0].name;

      // console.log(formData.getAll("file"));
    },

    save_solution() {
      this.$refs["solutionRef"].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (let course in this.solution) {
            formData.append(`${course}`, this.solution[course]);
          }
          // add submit solution image
          formData.append("img", this.$refs["submit_solution_file"].files[0]);
          this.$store
            .dispatch("courses/evaluation", {
              id: "",
              query: formData,
            })
            .then((_) => {
              this.refresh();
              this.solution_dialog = false;
              this.submit_solution_file_name = "";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    printprofilePage() {
      window.print();
    },
    save_solution2(teacher_id, course_id, student_id) {
      var student_id = this.child_id;
      this.solution = {
        group_id: this.$route.params.group_id,
        teacher_id: 25,
        course_id,
        student_id: student_id,
        selected: 0,
      };

      console.log(this.solution);
      let formData = new FormData();
      for (let course in this.solution) {
        console.log(this.solution[course]);
        formData.append(`${course}`, this.solution[course]);
      }
      // add submit solution image
      formData.append("file", this.imgurl);
      console.log(formData);
      this.$store
        .dispatch("courses/evaluation", {
          id: "",
          query: formData,
        })
        .then((res) => {});
    },
  },
};
</script>

<style scoped>
.feedback-input {
  display: flex;
  align-items: center;
}
.feedback-item-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust gap as needed */
}
.feedback-item {
  display: flex;
  align-items: center;
}

.input-inline {
  width: 400px; /* Adjust the width as needed */
  margin-right: 10px;
}

.note-item {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

::v-deep .el-form-item {
  position: relative;
}
::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* style for file input  */
.file_input {
  position: relative;
}
.input_file_placeholder {
  position: absolute;
  border: none;
  border-bottom: 1px solid #dcdfe6;
  width: calc(100% - 2em);
}

.input_file_placeholder::before {
  content: "";
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: 10;
  right: 0;
}
#ind {
  z-index: 1;
}
#hl {
  background-color: red;
  border: 1px solid black;
  z-index: 55;
}
#img2 {
  background-color: red;
  border: 1px solid black;
  z-index: 55;
}
.labls {
  /* border-bottom: 1px solid #f7f8f9; */
  padding: 5px;
  margin-right: 6px;
  cursor: pointer;
}
.labls:hover {
  background-color: #f7f8f9;
}
.labls2 {
  /* border-bottom: 1px solid #f7f8f9; */
  /* padding: 5px;
  margin-right: 6px; */
  cursor: pointer;
}
.labls2:hover {
  background-color: #f7f8f9;
}
.wdth {
  width: 50%;
}
#selectchildren {
  padding: 7px;
}
</style>

<style lang="scss" scoped>
.title_is_empty {
  position: absolute;
  top: 100px;
  font-size: 0.9rem;
  color: #f56c6c;
}

::v-deep .el-textarea__inner {
  height: 300px;
  resize: none;
}
</style>
